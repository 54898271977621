import { useNonce } from '@src/hooks/useNonce'
import { useEffect } from 'react'

const BlipChat = () => {
  const nonce = useNonce()
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window === 'undefined' || !nonce) return
    const script = document.createElement('script')
    script.async = true
    script.nonce = nonce
    script.integrity = 'sha384-ouECQb0S113KuvY42WfQQJyQG5GCD4zY6tLs6N4q7F/sPGHHk2W0RKmYG2HtCbr7';
    script.crossOrigin = 'anonymous';
    script.innerText = `(function () {
      new BlipChat()
        .withAppKey('Z2F6aW5jcmVkYXRlbmRpbWVudG86NzE5YmU3OWUtMjUwZS00YzYyLTkxZTctMjQ0MzBlZjNmMjFk')
        .withButton({ color: '#3D9DEF', icon: '' })
        .withCustomCommonUrl('https://chat.blip.ai/')
        .build()
    })()`

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [nonce])

  return null
}

export default BlipChat
