import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function useNonce() {
  const [nonce, setNonce] = useState<string | undefined>()
  const router = useRouter()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const metaNonce = document.querySelector('meta[property="csp-nonce"]')?.getAttribute('content')
      const windowNonce = (window as any).__nonce__

      setNonce(metaNonce || windowNonce)
    }
  }, [router.asPath])

  return nonce
}
