import Footer from '@components/Footer/Footer'
import Header from '@components/Header'
import * as Sentry from '@sentry/nextjs'
import { ICookiesConsent, IFooter, IMenuData, IPrivacyPolicyProps } from '@src/interfaces/Home.interface'
import { api } from '@src/service/api'
import { makeTitleWithSuffix } from '@src/utils/make-title-with-suffix'
import Head from 'next/head'
import { PropsWithChildren } from 'react'

export interface LayoutData {
  menuData: IMenuData
  footerData: IFooter
  cookiesData: ICookiesConsent
  privacyPolicyData: IPrivacyPolicyProps
  privacyPolicyPageData: IPrivacyPolicyProps
  nonce?: string
}

export interface LayoutDefaultProps extends LayoutData {
  title: string
  description?: string | null
  pageKey?: string
}

/**
 * Used to get props from getStaticProps/getServerSideProps
 */
export const getLayoutDefaultProps = async () => {
  const catchError = error => {
    Sentry.captureException(error)
    return Promise.resolve({ data: null })
  }

  const [
    { data: cookiesData },
    { data: menuData },
    { data: footerData },
    { data: privacyPolicyData },
    { data: privacyPolicyPageData },
  ] = await Promise.all([
    api.get('/cookies').catch(catchError),
    api.get('/menu').catch(catchError),
    api.get('/footer').catch(catchError),
    api.get('/privacy-policy').catch(catchError),
    api.get('/pages/key/politica-de-privacidade').catch(catchError),
  ])

  return {
    menuData,
    cookiesData,
    footerData,
    privacyPolicyData,
    privacyPolicyPageData,
  }
}

const PRIVACY_DATE_STORAGE = 'privacyPolicyDate'

export const LayoutDefault = ({
  privacyPolicyPageData,
  privacyPolicyData,
  pageKey,
  nonce,
  ...props
}: PropsWithChildren<LayoutDefaultProps>) => {
  const titleWithSuffix = makeTitleWithSuffix(props.title)

  const savaDatePrivacyPolicy = () => {
    if (privacyPolicyPageData && privacyPolicyPageData.updated_at) {
      localStorage.setItem(PRIVACY_DATE_STORAGE, privacyPolicyPageData.updated_at)
    }
  }

  if (typeof window !== 'undefined' && privacyPolicyData && privacyPolicyPageData) {
    privacyPolicyData.show = false
    if (pageKey === 'politica-de-privacidade') {
      savaDatePrivacyPolicy()
    } else {
      const privacyPolicyDateStorage = localStorage.getItem(PRIVACY_DATE_STORAGE)
      if (privacyPolicyDateStorage && privacyPolicyDateStorage.length > 0) {
        if (privacyPolicyDateStorage !== privacyPolicyPageData.updated_at) {
          privacyPolicyData.show = true
        }
      }
    }
  }

  return (
    <main>
      <Head>
        <title>{titleWithSuffix}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={props.description || ''} />
        <meta name="google-site-verification" content="yBbcRSca20DdZN3h8lpA-4CjuAl-rlKIIHebSJcMxgg" />
        <script
          type={'text/javascript'}
          async
          src={'https://d335luupugsy2.cloudfront.net/js/loader-scripts/338a8208-fbea-4882-94f7-0acf0b2f92c6-loader.js'}
          nonce={nonce}
        ></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WD541GMQEJ"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WD541GMQEJ');
            `,
          }}
        />
      </Head>
      <Header {...props.menuData} />
      {props.children}
      <Footer
        footerList={props.footerData}
        cookiesData={props.cookiesData}
        privacyPolicyData={privacyPolicyData}
        savaDatePrivacyPolicy={savaDatePrivacyPolicy}
        nonce={nonce}
      />
    </main>
  )
}
